import './about.css';
import React,{Component} from "react";

class About extends Component{
  componentDidMount(){
    const aboutcontent = document.querySelector('.aboutcontent');
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Adjust this value to determine how much of the element is in view
    };
    const aboutobserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                observer.unobserve(entry.target); // Stop observing once the animation has been triggered
            }
        });
    }, observerOptions);
    aboutobserver.observe(aboutcontent);

  }

  render(){
    return(
      <div className="about">
        <div className='aboutcontent'>
          <h1>{this.props.langdata.on_pic}</h1>
          <div></div>
        </div>
      </div>
    );
  }
}

export default About;
