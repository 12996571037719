import React from "react";
import "./contact.css";
import handshake from "./data/handshake.png";
import insta from "./data/instalogo.png";
import linkedin from "./data/linkedin.png";
import threads from "./data/threads.png";
class Contact extends React.Component{
    render(){
        /*return(
            <div className="contact">
                <h1>{this.props.langdata.Con}</h1>
                <div className="headerdiv">
                    <div>
                        <div>
                            <label>{this.props.langdata.Con1}</label>
                            <p>+91 78159 64557</p>
                        </div>
                        <div>
                            <label>{this.props.langdata.Con2}</label>
                            <p>(405)300-0409</p>
                        </div>
                        <div>
                            <label>{this.props.langdata.Con3}</label>
                            <p>2310 Parklake Drive Suite 273, Atlanta, GA 30345</p>
                        </div>
                        <div>
                            <label>{this.props.langdata.Con4}</label>
                            <p>(Plot. No.38&39, 2nd floor, Siddi Vinayak Nagar, Madhapur, Telangana-500081</p>
                        </div>
                        <div>
                            <label>{this.props.langdata.Con5}</label>
                            <p>Al Fajer Complex - Office 201-205 - Oud Metha - Dubai - United Arab Emirates</p>
                        </div>
                        <div>
                            <label>{this.props.langdata.Con6}</label>
                            <p>Williamson Dr W, Ajax, ON L1T 4X8, Canada</p>
                        </div>
                    </div>
                    <img src={handshake} alt="" ></img>
                </div>
            </div>
        );*/
        return(
            <div className="contactdiv">
                <div className="contactimgdiv">
                    <img src="https://storage.googleapis.com/cropcare-media/chart1.webp" alt="" className="chart"></img>
                </div>
                
                <div className="contactdifferentiator">
                    <h2>{this.props.langdata.more}</h2>
                </div>
                <div className="contact">
                <div className="contactcontent">
                    <h3>Crop care</h3>
                    <p>{this.props.langdata.team}</p>
                    <a href="https://www.instagram.com/artmacsoft/"><img src={insta} alt="" width="50px"></img> </a>
                    <a href="https://www.linkedin.com/company/artmac-soft-llc/"><img src={linkedin} alt="" width="50px"></img></a>
                    <a href="\"><img src={threads} alt="" width="50px"></img></a>
                </div>
                <div className="terms">
                    <a href="/terms">{this.props.langdata.Terms}</a>
                    <a href="/cookie">{this.props.langdata.Cookie}</a>
                    <a href="/privacy">{this.props.langdata.Privacy}</a>
                    <a href="/disclaimer">{this.props.langdata.Dis}</a>
                </div>
                </div>
                
            </div>
        )
    }
}
export default Contact;