import React from "react";
import Main from './main';
import Cards from './cards';
import About from './About';
import Player from "./player";
import Contact from "./contact";
class Page1 extends React.Component{
    constructor(){
        super();
        this.state={
            para:"",
            img:""
          }
    }
    render(){
        return(
            <div>
                <Main hider={this.props.hider} scroller = {this.props.scroller} lang = {this.props.lang} langdata = {this.props.langdata} ass = {this.props.ass}></Main>
                <Cards content ={this.props.content} lang = {this.props.lang} langdata = {this.props.langdata} ass = {this.props.ass}></Cards>
                <About langdata = {this.props.langdata}></About>
                {/*<Player langdata = {this.props.langdata}></Player>*/}
                <Contact langdata = {this.props.langdata}></Contact>
            </div>
        );
    }
}
export default Page1