import './main.css';
/*import cropcaremainlogo from "./data/Cropcare_main_logo.png";*/
import React,{Component} from "react";
import rice from "./data/riceimage.png";
import wheat from "./data/wheatimage.png";
import chilli from "./data/chilliimage.png";
import tobacco from "./data/tobaccoimage.png";
import corn from "./data/cornimage.png";
import millet from "./data/milletimage.png";
/*import { GoogleAuthProvider } from "firebase/auth";
import { getAuth,signInWithRedirect,getRedirectResult,signInWithCredential,signInWithPopup} from "firebase/auth";
import { initializeApp } from 'firebase/app';*/
/*
<div className="headline">
            <div className="headline_part">
                <h1>CropCare</h1>
                <p>{this.props.langdata.description}</p>
                <div className='twobuttons'>
                    <button id="getstart" href={`/workspace/${this.props.lang}/assist${this.props.ass}`}>{this.props.langdata.GS}</button>
                    <div className='tutorial' onClick={this.props.scroller}>
                      <div className='container'><div className="circle"><div className='triangle'></div></div></div>
                      <button id='tutorial'>{this.props.langdata.Tut}</button>
                    </div>
                </div>
            </div>
            <img src={cropcaremainlogo} alt="" width="500px" height="500px"></img>
        </div>
*/
class Main extends Component{
  
  
  render(){
    return(
    <div className="main">
        <div className='img-content'>
          <div><a href={`/workspace/${this.props.lang}/assist${this.props.ass}`}><img src={rice} alt='rice '></img></a><h4>{this.props.langdata.p1}</h4></div>
          <div onClick={this.props.hider}><button><img src={wheat} alt='wheat '></img></button><h4>{this.props.langdata.p2}</h4></div>
          <div onClick={this.props.hider}><button><img src={chilli} alt='wheat '></img></button><h4>{this.props.langdata.p3}</h4></div>
          <div onClick={this.props.hider}><button><img src={tobacco} alt='wheat '></img></button><h4>{this.props.langdata.p4}</h4></div>
          <div onClick={this.props.hider}><button><img src={corn} alt='wheat '></img></button><h4>{this.props.langdata.p5}</h4></div>
          <div onClick={this.props.hider}><button><img src={millet} alt='wheat '></img></button><h4>{this.props.langdata.p6}</h4></div>
          {/*
          <div><a href={`/workspace/${this.props.lang}/assist${this.props.ass}`}><img src={wheat} alt='wheat '></img></a></div>
          <div><a href={`/workspace/${this.props.lang}/assist${this.props.ass}`}><img src={chilli} alt='chilli '></img></a></div>
          <div><a href={`/workspace/${this.props.lang}/assist${this.props.ass}`}><img src={tobacco} alt='rice '></img></a></div>
          <div><a href={`/workspace/${this.props.lang}/assist${this.props.ass}`}><img src={corn} alt='wheat'></img></a></div>
          <div><a href={`/workspace/${this.props.lang}/assist${this.props.ass}`}><img src={millet} alt='chilli'></img></a></div>*/}
        </div>
        <div className='main-content'>
          {/*<div className='main-content-part1'>
          <div class="triangle-left"></div>
          </div>*/}
           <div>
          <label><b>Aha crop care</b></label>
          <p><b>{this.props.langdata.tag}</b></p>
          <p>{this.props.langdata.description}</p>
          </div>
          <div className='span-content'>
            <div className='span'></div>
            <div className='span'></div>
            <p>{this.props.langdata.selection}</p>
          </div>
         
        </div>
    </div>
    );
  }
}

export default Main;
