import './cards.css';
import React,{Component} from "react";
/*import paddy from "./data/paddy.png";*/
import yieldimg from "./data/yieldprediction.png";
import consultation from "./data/consultation.png";
import contract from "./data/contract.png";
import smart from "./data/smartfarming.png";

class Cards extends Component{
    constructor(){
        super()
        this.state = {
            slideIndex:0
        }
    }
componentDidMount(){
    const boxes = document.querySelectorAll('.box');
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Adjust this value to determine how much of the element is in view
    };
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                observer.unobserve(entry.target); // Stop observing once the animation has been triggered
            }
        });
    }, observerOptions);
    boxes.forEach(box => {
        observer.observe(box);
    });/*
    <div className='cards-sec'>
                <div id='box1' className="box" ><a href={`/card1/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease1}</h1></a></div>
                <div id='box2' className="box" ><a href={`/card2/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease2}</h1></a></div>
                <div id='box3' className="box" ><a href={`/card3/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease3}</h1></a></div>
                <div id='box4' className="box" ><a href={`/card4/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease4}</h1></a></div>
                <div id='box5' className="box" ><a href={`/card5/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease5}</h1></a></div>
                <div id='box6' className="box" ><a href={`/card6/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease6}</h1></a></div>
                <div id='box7' className="box" ><a href={`/card7/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease7}</h1></a></div>
                <div id='box8' className="box" ><a href={`/card8/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease8}</h1></a></div>
                <div id='box9' className="box" ><a href={`/card9/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease9}</h1></a></div>
                <div id='box10' className="box"><a href={`/card10/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease10}</h1></a></div>
                <div id='box11' className="box"><a href={`/card11/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease11}</h1></a></div>
                <div id='box12' className="box"><a href={`/card12/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease12}</h1></a></div>
            </div>    
    
    
    
    */
}




  render(){
    return(
    <div className="cards">
       
        <div className='box'>
        <a href='/yield'>
                <div className='cardimgdiv'>
                    <img src={yieldimg} alt=''></img>
                </div>
                <div>
                    <h1>{this.props.langdata.yp}</h1>
                </div>
                </a>
        </div>
        
        
        <div className='box'>
        <a href='/smartfarming'>
                <div className='cardimgdiv'>
                <img src={smart} alt=''></img>
                </div>
                <div>
                    <h1>{this.props.langdata.sf}</h1>
                </div>
                </a>
        </div>
       
        
        <div className='box'>
        <a href='/contractfarming'>
                <div className='cardimgdiv'>
                <img src={contract} alt=''></img>
                </div>
                <div>
                    <h1>{this.props.langdata.cf}</h1>
                </div>
                </a>  
        </div>
       
        
        <div className='box'>
        <a href='/consultation'>
                <div className='cardimgdiv'>
                <img src={consultation} alt=''></img>
                </div>
                <div>
                    <h1>{this.props.langdata.fc}</h1>
                </div>
                </a>
        </div>
        
      </div>
      
    );
  }
}

export default Cards;
